import React from "react"

import { Heading, Stack, Text, TextLink } from "@kiwicom/orbit-components/lib/"
import NewWindow from "@kiwicom/orbit-components/lib/icons/NewWindow"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Policies = () => (
  <DriveLayout title="Company Updates">
    <Hero title="Company Updates" />
    <Container>
      <Stack spacing="XLarge">
        <EmberCard>
          <EmberCardSection>
            <Stack direction="column">
              <Text>
                Andy, Keith and Pierce will post a monthly company update with
                news, responses to your questions and an insight into future
                plans. We'll keep the archive here so that you can read current
                and past updates all in one place. The regular updates started
                in October 2022.
              </Text>
              <Text>
                If you have an idea, question or piece of feedback you'd like
                addressed in the next update then you can{" "}
                <TextLink
                  external
                  href="https://notionforms.io/forms/feedback-and-questions"
                  iconRight={<NewWindow size="small" />}
                >
                  submit it here
                </TextLink>
                .
              </Text>
              <Heading type="title5">2024</Heading>
              <TextLink href="2024-08/">August 2024</TextLink>
              <TextLink href="2024-07/">July 2024</TextLink>
              <TextLink href="2024-06/">June 2024</TextLink>
              <TextLink href="2024-05/">May 2024</TextLink>
              <TextLink href="2024-04/">April 2024</TextLink>
              <TextLink href="2024-03/">March 2024</TextLink>
              <TextLink href="2024-02/">February 2024</TextLink>
              <TextLink href="2024-01/">January 2024</TextLink>
              <Heading type="title5">2023</Heading>
              <TextLink href="2023-11/">November 2023</TextLink>
              <TextLink href="2023-10/">October 2023</TextLink>
              <TextLink href="2023-09/">September 2023</TextLink>
              <TextLink href="2023-08/">August 2023</TextLink>
              <TextLink href="2023-07/">July 2023</TextLink>
              <TextLink href="2023-06/">June 2023</TextLink>
              <TextLink href="2023-05/">May 2023</TextLink>
              <TextLink href="2023-04/">April 2023</TextLink>
              <TextLink href="2023-03/">March 2023</TextLink>
              <TextLink href="2023-02/">February 2023</TextLink>
              <TextLink href="2023-01/">January 2023</TextLink>
              <Heading type="title5">2022</Heading>
              <TextLink href="2022-12/">December 2022</TextLink>
              <TextLink href="2022-11/">November 2022</TextLink>
              <TextLink href="2022-10/">October 2022</TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Policies
